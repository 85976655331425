<template>
  <!-- Features -->
  <div id="features" class="tabs bg-gray">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">{{ $t("slideTitle") }}</div>
          <h2 class="h2-heading">{{ $t("slideSubTitle") }}</h2>
          <p class="p-heading">{{ $t("slideBody") }}</p>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
      <div class="row">
        <!-- Tabs Links -->
        <ul class="nav nav-tabs" id="navTabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="nav-tab-1" data-bs-toggle="tab" data-bs-target="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true"
              ><i class="fas fa-list"></i>{{ $t("slideElementOneTitle") }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" id="nav-tab-2" data-bs-toggle="tab" data-bs-target="#tab-2" role="tab" aria-controls="tab-2" aria-selected="false"
              ><i class="fas fa-envelope-open-text"></i>{{ $t("slideElementTwoTitle") }}</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" id="nav-tab-3" data-bs-toggle="tab" data-bs-target="#tab-3" role="tab" aria-controls="tab-3" aria-selected="false"
              ><i class="fas fa-chart-bar"></i>{{ $t("slideElementThreeTitle") }}</a
            >
          </li>
        </ul>
        <!-- end of tabs links -->

        <!-- Tabs Content-->
        <div class="tab-content" id="navTabsContent">
          <!-- Tab -->
          <div class="tab-pane fade show active" id="tab-1" role="tabpanel" aria-labelledby="nav-tab-1">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="image-container">
                    <img class="img-fluid" src="images/02.svg" alt="alternative" />
                  </div>
                  <!-- end of image-container -->
                </div>
                <!-- end of col -->
                <div class="col-lg-6">
                  <div class="text-container">
                    <h3>{{ $t("slideElementOneTitle") }}</h3>
                    <p>{{ $t("slideElementOneBody") }}</p>
                    <ul class="list-unstyled li-space-lg">
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementOneItemOne") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementOneItemTwo") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementOneItemThree") }}</div>
                      </li>
                    </ul>
                    <a class="btn-solid-reg" href="https://admin.bookerix.com/register" target="_blank">{{ $t("trial15days") }}</a>
                  </div>
                  <!-- end of text-container -->
                </div>
                <!-- end of col -->
              </div>
              <!-- end of row -->
            </div>
            <!-- end of container -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->

          <!-- Tab -->
          <div class="tab-pane fade" id="tab-2" role="tabpanel" aria-labelledby="nav-tab-2">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="image-container">
                    <img class="img-fluid" src="images/03.svg" alt="alternative" />
                  </div>
                  <!-- end of image-container -->
                </div>
                <!-- end of col -->
                <div class="col-lg-6">
                  <div class="text-container">
                    <h3>{{ $t("slideElementTwoTitle") }}</h3>
                    <p>{{ $t("slideElementTwoBody") }}</p>
                    <ul class="list-unstyled li-space-lg">
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementTwoItemOne") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementTwoItemTwo") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementTwoItemTwo") }}</div>
                      </li>
                    </ul>
                    <a class="btn-solid-reg page-scroll" href="https://admin.bookerix.com/register" target="_blank">{{ $t("trial15days") }}</a>
                  </div>
                  <!-- end of text-container -->
                </div>
                <!-- end of col -->
              </div>
              <!-- end of row -->
            </div>
            <!-- end of container -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->

          <!-- Tab -->
          <div class="tab-pane fade" id="tab-3" role="tabpanel" aria-labelledby="nav-tab-3">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="image-container">
                    <img class="img-fluid" src="images/05.svg" alt="alternative" />
                  </div>
                  <!-- end of image-container -->
                </div>
                <!-- end of col -->
                <div class="col-lg-6">
                  <div class="text-container">
                    <h3>{{ $t("slideElementThreeTitle") }}</h3>
                    <p>{{ $t("slideElementThreeBody") }}</p>
                    <ul class="list-unstyled li-space-lg">
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementThreeItemOne") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementThreeItemTwo") }}</div>
                      </li>
                      <li class="d-flex">
                        <i class="fas fa-square"></i>
                        <div class="flex-grow-1">{{ $t("slideElementThreeItemThree") }}</div>
                      </li>
                    </ul>
                    <a class="btn-solid-reg page-scroll" href="https://admin.bookerix.com/register" target="_blank">{{ $t("trial15days") }}</a>
                  </div>
                  <!-- end of text-container -->
                </div>
                <!-- end of col -->
              </div>
              <!-- end of row -->
            </div>
            <!-- end of container -->
          </div>
          <!-- end of tab-pane -->
          <!-- end of tab -->
        </div>
        <!-- end of tab-content -->
        <!-- end of tabs content -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of tabs -->
  <!-- end of features -->
</template>
