<template>
  <!-- Header -->
  <header id="header" class="header">
    <div class="container2" style="max-width: 90%; width: 90%; padding: 0 10%">
      <div class="row">
        <div class="col-lg-8">
          <div class="text-container">
            <h1 class="h1-large">{{ $t("headerTitle") }}</h1>
            <p class="p-large">
              {{ $t("headerContent") }}
            </p>
            <a class="btn-solid-lg" href="https://admin.bookerix.com/register" target="_blank">{{ $t("trial15days") }}</a>
            <a class="btn-outline-lg mt-4" href="#description">{{ $t("readMore") }}</a>
          </div>
          <!-- end of text-container -->
        </div>
        <!-- end of div -->
        <div class="col-lg-4" style="align-items: center; display: flex">
          <div class="image-container">
            <img class="img-fluid" src="images/01.png" alt="alternative" />
          </div>
          <!-- end of image-container -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
    <svg class="header-decoration" data-name="Layer 3" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" viewBox="0 0 1920 305.139">
      <title>header-decoration</title>
      <path
        class="cls-1"
        fill="#ffffff"
        d="M1486.486,36.773C1434.531,13.658,1401.068-5.1,1329.052,1.251c-92.939,8.2-152.759,70.189-180.71,89.478-23.154,15.979-134.286,104.091-171.753,128.16-50.559,32.48-98.365,59.228-166.492,67.5-67.648,8.21-124.574-6.25-152.992-18-42.218-17.454-42.218-17.454-90-39-35.411-15.967-81.61-34.073-141.58-34.054-116.6.037-262.78,77.981-354.895,80.062C53.1,275.793,22.75,273.566,0,260.566v44.573H1920V61.316c-36.724,23.238-76.008,61.68-177,65C1655.415,129.2,1556.216,67.8,1486.486,36.773Z"
        transform="translate(0 0)"
      />
    </svg>
  </header>
  <!-- end of header -->
  <!-- end of header -->
</template>
