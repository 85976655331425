<template>
  <!-- Footer -->
  <div class="footer-decoration">
    <svg
      class="footer-frame"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      viewBox="0 0 1920 115.984"
    >
      <defs></defs>
      <title>footer-frame</title>
      <path
        fill="#480295"
        class="cls-2"
        d="M1616.28,381.705c-87.656,5.552-93.356-59.62-197.369-67.562-112.391-8.581-137.609,65.077-251.189,60.632-109.57-4.288-116.156-74.017-225.25-83.153-125.171-10.483-150,78.5-293,88.35-136.948,9.437-173.108-68.092-320.83-77.956C255.5,297.133,143,308.1,0,395.564V406.75H1920V324.537c-18-6.507-43.63-14.492-76.1-15.591C1740.655,305.452,1705.829,376.033,1616.28,381.705Z"
        transform="translate(0 -290.766)"
      />
    </svg>
  </div>
  <!-- end of footer-decoration -->
  <div class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-col first">
            <h6>Bookerix</h6>
            <p class="p-small">{{ $t("footerSlogan") }}</p>
          </div>
          <!-- end of footer-col -->
          <div class="footer-col second">
            <h6>Links</h6>
            <ul class="list-unstyled li-space-lg p-small">
              <li>
                <router-link to="terms">{{ $t("terms") }}</router-link>
              </li>
              <li>
                <router-link to="privacy">{{ $t("privacy") }}</router-link>
              </li>
              <li>
                <a href="https://admin.bookerix.com/login" target="_blank">{{
                  $t("access_panel")
                }}</a>
              </li>
              <li>
                <a href="https://admin.bookerix.com/register" target="_blank">{{
                  $t("signIn")
                }}</a>
              </li>
            </ul>
          </div>
          <!-- end of footer-col -->
          <div class="footer-col third">
            <span class="fa-stack">
              <a href="https://www.facebook.com/Bookerix/">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="https://www.instagram.com/Bookerix/">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-instagram fa-stack-1x"></i>
              </a>
            </span>
            <p class="p-small" v-html="$t('contactUs')"></p>
          </div>
          <!-- end of footer-col -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of footer -->
  <!-- end of footer -->

  <!-- Copyright -->
  <div class="copyright">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p class="p-small">
            Copyright © 2023 <a href="https://bookerix.com">Bookerix 💜</a>
          </p>
        </div>
        <!-- end of col -->
      </div>
      <!-- enf of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of copyright -->
  <!-- end of copyright -->
</template>
