import { createI18n } from "vue-i18n";

const messages = {
  es: {
    benefits: "Beneficios",
    access_panel: "Acceso al panel",
    features: "Inicio",
    home: "Caracterísiticas",
    pricing: "Precios",
    more: "Más",
    signIn: "Registro",
    headerTitle:
      "Automatiza la gestión de tus citas y reservas con Bookerix Asistente Digital.",
    headerContent:
      "Comunícate fluídamente con clientes y usuarios: Ahorra tiempo y enfócate en tu negocio, mientras nuestro asistente digital se encarga de simplificar la gestión y optimizar la organización. Gestiona tus citas, clientes y ventas de forma automática con nuestra plataforma de asistencia digital de servicios.",
    trial15days: "Prueba 15 días gratis",
    readMore: "Saber Más",
    simplify: "Simplifica",
    gridTitle: "La comunicación y gestión de citas para sesiones, turnos, clases, cursos, talleres, u otros, desde una única aplicación.",
    gridSubtitleElementOne: "Comunicación automática por Whatsapp",
    gridBodyElementOne:
      "Tus clientes pueden de agendar sus citas directamente a través de WhatsApp. También, podrán confirmar, cancelar o reagendar sus citas de manera automática, según los horarios disponibles en tu calendario.",
    gridTitleElementTwo: "Citas sincronizadas con Google Calendar",
    gridBodyElementTwo:
      "Adminístra tus citas de manera eficiente a través de tu calendario, donde podrás agendarlas y gestionarlas de forma sencilla.",
    gridTitleElementThree: "Recibe tus pagos online",
    gridBodyElementThree:
      "Diversifica las opciones de pago para tus servicios: brinda flexibilidad a tus clientes al ofrecer métodos de pago como transferencia, efectivo, Mercado Pago y otros, adaptándote a sus necesidades y preferencias.",
    benefitsTitle: "Con Bookerix simplifica tus tareas administrativas",
    benefitsBody:
      "Al brindar servicios, se necesitan respuestas rápidas y una organización cada día más eficiente. Al automatizar tareas los profesionales pueden enfocarse en ofrecer servicios y brindar una experiencia superior a sus clientes.",
    benefitsListOne:
      "Reservas y agenda: Ya sea por Whatsapp o en tu web, los clientes pueden seleccionar y reservar citas de forma automática, evitando así el intercambio manual para programar citas.",
    benefitsListTwo:
      "Recordatorios y confirmaciones automatizadas: Tu asistente virtual, puede enviar automáticamente recordatorios y confirmaciones de citas a los clientes. Reduce las ausencias y garantiza que los clientes estén informados.",
    benefitsListThree:
      "Pagos en línea: Agiliza el proceso de cobro, ya sea antes de la cita o al momento de reservar. Maneja pagos parciales o totales.",
    newsletter: "NEWSLETTER",
    contactFormTitle: "Mantente actualizado con las últimas novedades",
    terms_: "He leído y acepto las",
    privacy: "Politicas de Privacidad",
    and: "y",
    terms: "Términos & Condiciones",
    send: "Enviar",
    footerSlogan:
      "Gestiona tus citas, clientes y ventas de forma automática con nuestra plataforma de asistencia digital de servicios.",
    contactUs:
      "Si tienes alguna sugerencia, puedes ponerte en contacto enviando un correo eletrónico a hola@bookerix.com y pronto nos pondremos en contacto.",

    slideTitle: "Especialización",
    slideSubTitle:
      "Tu asistente digital se encargará de agendar, confirmar y reagendar citas de manera simple",
    slideBody:
      "Al tener tu asistente digital conectado a tu tienda de servicios, tendrás mayor visibilidad de tu negocio, gestión simplificada de citas, maximización de ingresos, mejor servicio al cliente, automatización de recordatorios, administración eficiente, entre otros.",
    slideElementOneTitle: "Belleza y cuidado personal",
    slideElementTwoTitle: "Bienestar",
    slideElementThreeTitle: "Otros negocios",
    slideElementOneBody: "Resolvemos la problemática específica del ecosistema",
    slideElementTwoBody:
      "La dinámica de la reserva de citas y brindar los servicios puede ser un problema a la hora de administrar el tiempo.",
    slideElementThreeBody:
      "Si eres pequeño, mediano o grande, Bookerix se adapta a los distintos tipos de negocio que tengan la necesidad de automatizar la interacción con clientes, reserva de turnos y gestión simple y centralizada.",
    slideElementOneItemOne: "Centros de estética / Peluquerías / Barberías",
    slideElementOneItemTwo: "Spas / Manicure / Pedicure",
    slideElementOneItemThree: "Salones de belleza",
    slideElementTwoItemOne: "Terapias alternativas / complementarias",
    slideElementTwoItemTwo: "Clases de pilates",
    slideElementTwoItemThree: "Clases de yoga",
    slideElementThreeItemOne: "Reparadores",
    slideElementThreeItemTwo: "Quienes brindan servicios",
    slideElementThreeItemThree:
      "Quienes tengan la necesidad de optimizar su tiempo y trabajar de forma simple con reservas y gestión de turnos",
    pricesSubTitle: "Planes y adicionales",
    contac: "Contactar",
    buy: "Contratar",
    applyConditions:
      "El uso y la transferencia de información recibida de las API de Google a cualquier otra aplicación se adherirán a la",
    applyConditions2:
      "Política de datos de usuario de los servicios API de Google",
    applyConditions3: "incluidos los requisitos de uso limitado.",
    applyConditions:
      "El uso y la transferencia de información recibida de las API de Google a cualquier otra aplicación se adherirán a la",
    applyConditions2:
      "Política de datos de usuario de los servicios API de Google",
    applyConditions3: "incluidos los requisitos de uso limitado.",
    applyConditions4: "Google Calendar™ calendaring application es una marca registrada de Google LLC.",
  },
  en: {
    benefits: "Benefits",
    access_panel: "Dashboard Access",
    features: "Features",
    home: "Home",
    pricing: "Pricing",
    more: "More",
    signIn: "Sign in",
    headerTitle:
      "Automate Your Appointment and Booking Management with Bookerix Digital Assistant.",
    headerContent:
      "Communicate smoothly with clients and users: Save time and focus on your business, while our digital assistant takes care of simplifying management and optimizing organization. Manage your appointments, clients, and sales automatically with our digital service assistance platform.",
    readMore: "Read more",
    trial15days: "15-Day Free Trial",
    simplify: "Simplify",
    gridTitle:
      "Communication and appointment management from a single application.",
    gridSubtitleElementOne: "Automated Communication via WhatsApp",
    gridBodyElementOne:
      "Your customers can schedule their appointments directly through WhatsApp. Additionally, they can automatically confirm, cancel, or reschedule their appointments based on the available time slots in your calendar.",
    gridTitleElementTwo: "Citas sincronizadas con Google Calendar",
    gridBodyElementTwo:
      "Efficiently manage your appointments through your calendar, where you can schedule and handle them with ease.",
    gridTitleElementThree: "Receive Your Online Payments",
    gridBodyElementThree:
      "Diversify payment options for your services: Provide flexibility to your clients by offering payment methods such as bank transfer, cash, Mercado Pago, and others, adapting to their needs and preferences.",
    benefitsTitle: "Simplify Your Administrative Tasks with Bookerix",
    benefitsBody:
      "When providing services, quick responses and increasingly efficient organization are necessary. By automating tasks, professionals can focus on delivering services and providing a superior customer experience.",
    benefitsListOne:
      "Reservations and Schedule: Whether through WhatsApp or on your website, customers can select and book appointments automatically, avoiding manual back-and-forth for appointment scheduling.",
    benefitsListTwo:
      "Automated Reminders and Confirmations: Your virtual assistant can automatically send appointment reminders and confirmations to clients. Reduce no-shows and ensure clients are informed.",
    benefitsListThree:
      "Online Payments: Streamline the payment process, whether before the appointment or at the time of booking. Handle partial or full payments.",

    newsletter: "NEWSLETTER",
    contactFormTitle: "Stay Updated with the Latest News",
    terms_: "I have read and accept the",
    privacy: "Privacy Policies",
    and: "and",
    terms: "Terms & Conditions",
    send: "Send",
    footerSlogan:
      "Manage your appointments, clients, and sales automatically with our digital service assistance platform",
    contactUs:
      "If you have any questions or suggestions, you can get in touch by sending an email to holabookerix.com and we will get back to you soon.",
    slideTitle: "Specialization",
    slideSubTitle:
      "Your digital assistant will handle scheduling, confirming, and rescheduling appointments seamlessly",
    slideBody:
      "By having your digital assistant connected to your service storefront, you'll gain greater visibility into your business, simplified appointment management, revenue maximization, improved customer service, automated reminders, efficient administration, and more.",
    slideElementOneTitle: "Beauty and Personal Care",
    slideElementTwoTitle: "Wellness",
    slideElementThreeTitle: "Other Businesses",
    slideElementOneBody:
      "We address the specific challenges within the ecosystem.",
    slideElementTwoBody:
      "The dynamics of appointment booking and service delivery can pose a time management challenge.",
    slideElementThreeBody:
      "Whether you're small, medium, or large, Bookerix adapts to different types of businesses that need to automate customer interactions, appointment scheduling, and centralized management.",
    slideElementOneItemOne: "Esthetic Centers / Hair Salons / Barbershops",
    slideElementOneItemTwo: "Spas / Manicures / Pedicures",
    slideElementOneItemThree: "Beauty Salons",
    slideElementTwoItemOne: "Alternative / Complementary Therapies",
    slideElementTwoItemTwo: "Pilates Studios",
    slideElementTwoItemThree: "Yoga Studios",
    slideElementThreeItemOne: "Repair Services",
    slideElementThreeItemTwo: "Service Providers",
    slideElementThreeItemThree:
      "Businesses needing to optimize appointment scheduling and management.",
    pricesSubTitle: "Plans and Add-ons",
    contac: "Contact us",
    buy: "Buy now",
    applyConditions:
      "use and transfer of information received from Google APIs to any other app will adhere to",
    applyConditions2: "Google API Services User Data Policy",
    applyConditions3: "including the Limited Use requirements.",
    applyConditions4: "Google Calendar™ calendaring application is a trademark of Google LLC.",
  },
};
const browserLang = navigator.language.split("-")[0]; // Obtiene el idioma del navegador

const i18n = createI18n({
  legacy: false,
  locale: browserLang == "en" || browserLang == "es" ? browserLang : "en", // Usar el idioma del navegador
  globalInjection: true,
  messages: messages,
});

export default i18n;
