<template>
  <!-- Details -->
  <div id="details" class="basic-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="text-container">
            <h2>{{ $t("benefitsTitle") }}</h2>
            <p>
              {{ $t("benefitsBody") }}
            </p>
            <p></p>
            <ul class="list-unstyled li-space-lg">
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">
                  {{ $t("benefitsListOne") }}
                </div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">
                  {{ $t("benefitsListTwo") }}
                </div>
              </li>
              <li class="d-flex">
                <i class="fas fa-square"></i>
                <div class="flex-grow-1">
                  {{ $t("benefitsListThree") }}
                </div>
              </li>
            </ul>
            <a class="btn-solid-reg" href="https://admin.bookerix.com/register" target="_blank">{{ $t("trial15days") }}</a>
          </div>
          <!-- end of text-container -->
        </div>
        <!-- end of col-->
        <div class="col-lg-6">
          <div class="image-container">
            <img class="img-fluid" src="images/04.svg" alt="alternative" />
          </div>
          <!-- end of image-container -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of basic-1 -->
  <!-- end of details -->
</template>
