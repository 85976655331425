<template>
  <!-- Description -->
  <div id="description" class="cards-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">{{ $t("simplify") }}</div>
          <h2 class="h2-heading">{{ $t("gridTitle") }}</h2>
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
      <div class="row">
        <div class="col-lg-12">
          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <img
                class="img-fluid"
                src="images/description-icon-1.svg"
                alt="alternative"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ $t("gridSubtitleElementOne") }}</h5>
              <p>
                {{ $t("gridBodyElementOne") }}
              </p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <img
                class="img-fluid"
                src="images/description-icon-2.svg"
                alt="alternative"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ $t("gridTitleElementTwo") }}*</h5>
              <p>{{ $t("gridBodyElementTwo") }}</p>
              <hr />
              <p class="mt-2 italic text-sm">
                * {{ $t("applyConditions") }}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  >{{ $t("applyConditions2") }}</a
                >
                {{ $t("applyConditions3") }}
              </p>
              <p class="italic text-sm">
                {{ $t("applyConditions4") }}
              </p>
            </div>
          </div>
          <!-- end of card -->

          <!-- Card -->
          <div class="card">
            <div class="card-image">
              <img
                class="img-fluid"
                src="images/description-icon-3.svg"
                alt="alternative"
              />
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ $t("gridTitleElementThree") }}</h5>
              <p>
                {{ $t("gridBodyElementThree") }}
              </p>
            </div>
          </div>
          <!-- end of card -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of cards-1 -->
  <!-- end of description -->
</template>
