<template>
  <TheNavbar />

  <TheHeader />
  <GridElements />
  <SlideElements />
  <TwoColumn />
  <ThePlans />
  <ContactForm />
  <TheFooter />
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";
import GridElements from "@/components/GridElements.vue";
import SlideElements from "@/components/SlideElements.vue";
import TheFooter from "@/components/TheFooter.vue";
import TheHeader from "@/components/TheHeader.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import ThePlans from "@/components/ThePlans.vue";
import TwoColumn from "@/components/TwoColumn.vue";

export default {
  components: {
    TheFooter,
    TheNavbar,
    ContactForm,
    ThePlans,
    GridElements,
    SlideElements,
    TwoColumn,
    TheHeader,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
