<template>
  <router-view />
  <!-- built files will be auto injected -->
  <button onclick="topFunction()" id="myBtn">
    <img src="images/up-arrow.png" alt="alternative" />
  </button>
  <!-- end of back to top button -->
</template>

<script>
  export default {
    mounted() {
      window.scrollTo(0, 0);
    },
  };
</script>
