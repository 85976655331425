<template>
  <!-- Newsletter -->
  <div class="form-1">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="form-container bg-gray">
            <div class="section-title">{{ $t("newsletter") }}</div>
            <h2 class="h2-heading">{{ $t("contactFormTitle") }}</h2>

            <!-- Newsletter Form -->
            <form>
              <div class="mb-4 form-floating">
                <input type="email" class="form-control" id="floatingInput3" placeholder="name@example.com" />
                <label for="floatingInput3">Email</label>
              </div>
              <div class="mb-4 form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1" />
                <label class="form-check-label" for="exampleCheck1"
                  >{{ $t("terms_") }} <a href="privacy.html">{{ $t("privacy") }}</a> {{ $t("and") }} <a href="terms.html">{{ $t("terms") }}</a></label
                >
              </div>
              <button type="submit" class="form-control-submit-button">{{ $t("send") }}</button>
            </form>
            <!-- end of newsletter form -->
          </div>
          <!-- end of form-container -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of form-1 -->
  <!-- end of newsletter -->

  <!-- Social -->
  <div class="basic-3">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="icon-container">
            <span class="fa-stack">
              <a href="https://www.facebook.com/Bookerix/">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-facebook-f fa-stack-1x"></i>
              </a>
            </span>
            <span class="fa-stack">
              <a href="https://www.instagram.com/Bookerix/">
                <i class="fas fa-circle fa-stack-2x"></i>
                <i class="fab fa-instagram fa-stack-1x"></i>
              </a>
            </span>
          </div>
          <!-- end of col -->
        </div>
        <!-- end of col -->
      </div>
      <!-- end of row -->
    </div>
    <!-- end of container -->
  </div>
  <!-- end of basic-3 -->
  <!-- end of social -->
</template>
