<template>
  <div id="pricing" class="cards-2">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="section-title">{{ $t("pricing") }}</div>
          <h2 class="h2-heading">{{ $t("pricesSubTitle") }}</h2>
        </div>
        <div class="col-lg-12">
          <div class="card" v-for="p in plans" :key="p.id">
            <div class="card-body">
              <div class="card-title">{{ p[lang].name }}</div>
              <div class="price">
                <span class="currency">{{ p[lang].currency }}</span
                ><span class="value">{{ p[lang].value }}</span>
              </div>
              <div class="frequency">{{ p[lang].periodicity }}</div>
              <div class="frequency">{{ p[lang].subtitle }}</div>
              <hr class="cell-divide-hr" />
              <ul class="list-unstyled li-space-lg">
                <li class="d-flex" v-for="i in p[lang].items" :key="i">
                  <i class="fas fa-check"></i>
                  <div class="flex-grow-1">{{ i }}</div>
                </li>
              </ul>
              <template v-if="p.cta == 'trial'">
                <div class="button-wrapper">
                  <a
                    class="btn-solid-reg"
                    href="https://admin.bookerix.com/register"
                    target="_blank"
                    >{{ $t("trial15days") }}</a
                  >
                </div>
              </template>
              <template v-else-if="p.cta == 'contactUs'">
                <div class="button-wrapper">
                  <a
                    class="btn-solid-reg"
                    href="https://admin.bookerix.com/register"
                    target="_blank"
                    >{{ $t("contac") }}</a
                  >
                </div>
              </template>
              <template v-else-if="p.cta == 'buy'">
                <div class="button-wrapper">
                  <a
                    class="btn-solid-reg"
                    href="https://admin.bookerix.com/register"
                    target="_blank"
                    >{{ $t("buy") }}</a
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: "en",
      plans: [
        {
          id: 1,
          es: {
            name: "PERSONAL",
            value: "9.850",
            currency: "$",
            subtitle: "15 días gratis",
            periodicity: "/mensual",
            items: [
              "1 único servicio con agenda online para citas y clases ilimitadas.",
              "Incluye hasta 30 conversaciones de Whatsapp(*) / 1.000 mensajes.",
              "Hasta 100 Notificaciones por mail.",
              "Reportes con citas diaria, semanal, mensual.",
              "Posibilidad de contratar pack de conversaciones de Whatsapp Adicionales(*).",
            ],
          },
          en: {
            name: "PERSONAL",
            value: "10",
            currency: "USD",
            subtitle: "15-Day Free Trial",
            periodicity: "/monthly",

            items: [
              "1 unique service with online scheduling for unlimited appointments and classes.",
              "Includes up to 30 WhatsApp conversations(*) / 1,000 messages.",
              "Up to 100 Email Notifications.",
              "Reports with daily, weekly, monthly appointments.",
              "Option to purchase additional(*) WhatsApp conversation packs.",
            ],
          },

          cta: "trial",
        },
        {
          is: 2,
          es: {
            name: "ADVANCED",
            value: "17.999",
            subtitle: "15 días gratis",
            periodicity: "/mensual",
            items: [
              "Servicios ILIMITADOS con agenda online para citas y clases ilimitadas.",
              "Incluye hasta 70 conversaciones de Whatsapp(*) / 2.500 mensajes.",
              "Hasta 1000 Notificaciones por mail.",
              "Reportes con citas diaria, semanal, mensual.",
              "Posibilidad de utilizar tu propio Whatsapp Business o contratar pack de conversaciones de Whatsapp(*) adicionales.",
            ],
            currency: "$",
          },
          en: {
            name: "ADVANCED",
            value: "20",
            subtitle: "15-Day Free Trial",
            periodicity: "/monthly",
            currency: "USD",
            items: [
              "UNLIMITED services with online scheduling for unlimited appointments and classes.",
              "Includes up to 70 WhatsApp conversations(*) / 2,500 messages.",
              "Up to 1000 Email Notifications.",
              "Reports with daily, weekly, monthly appointments.",
              "Option to use your own Whatsapp Business or purchase additional(*) WhatsApp conversation packs.",
            ],
          },
          cta: "trial",
        },
        {
          id: 3,
          es: {
            name: "ENTERPRISE",
            value: "Solicitar cotización",
            subtitle: "",
            periodicity: "",
            items: ["Próximamente", "Custom Plan"],
            currency: "",
          },
          en: {
            currency: "",
            name: "ENTERPRISE",
            value: "Request a Quote",
            subtitle: "",
            periodicity: "",
            items: ["Coming Soon", "Custom Plan"],
          },
          cta: "contactUs",
        },
        {
          id: 4,
          es: {
            name: "ADICIONAL: PACK WHATSAPP30",
            value: "7.850",
            subtitle: "",
            periodicity: "bajo demanda",
            items: [
              "30 conversaciones de Whatsapp(*) por mes / 1.000 mensajes.",
              "(*) Conversaciones de Whatsapp: Son todos los mensajes con un número único de Whatsapp durante 24 horas, contadas a partir del primer mensaje enviado o recibido. Una vez transcurridas las 24 horas, se inicia una nueva conversación. Puedes realizar más de una conversación por día que se descontará del total de conversaciones.",
            ],
            currency: "$",
          },
          en: {
            name: "ADD-ON: PACK WHATSAPP30",
            value: "8",
            subtitle: "",
            periodicity: "one-time",
            items: [
              "30 WhatsApp conversations(*) per month / 1,000 messages.",
              "(*) WhatsApp Conversations: These are all messages with a unique WhatsApp number for 24 hours, counted from the first message sent or received. Once the 24 hours are up, a new conversation starts. You can have more than one conversation in a day, and it will be deducted from the total conversations.",
            ],
            currency: "USD",
          },
          cta: "buy",
        },
        {
          id: 5,
          es: {
            name: "ADICIONAL: PACK WHATSAPP50",
            value: "13.350",
            subtitle: "",
            periodicity: "bajo demanda",
            items: [
              "50 conversaciones de Whatsapp(*) por mes / 1.750 mensajes.",
              "(*) Conversaciones de Whatsapp: Son todos los mensajes con un número único de Whatsapp durante 24 horas, contadas a partir del primer mensaje enviado o recibido. Una vez transcurridas las 24 horas, se inicia una nueva conversación. Puedes realizar más de una conversación por día que se descontará del total de conversaciones.",
            ],
            currency: "$",
          },
          en: {
            name: "ADD-ON: PACK WHATSAPP50",
            value: "15",
            subtitle: "",
            periodicity: "one-time",
            items: [
              "50 WhatsApp conversations(*) per month / 1,750 messages.",
              "(*) WhatsApp Conversations: These are all messages with a unique WhatsApp number for 24 hours, counted from the first message sent or received. Once the 24 hours are up, a new conversation starts. You can have more than one conversation in a day, and it will be deducted from the total conversations.",
            ],
            currency: "USD",
          },
          cta: "buy",
        },
        {
          id: 6,
          es: {
            name: "ADICIONAL: PACK WHATSAPP100",
            value: "24.830",
            subtitle: "",
            periodicity: "bajo demanda",
            items: [
              "100 conversaciones de Whatsapp(*) por mes / 3.500 mensajes.",
              "(*) Conversaciones de Whatsapp: Son todos los mensajes con un número único de Whatsapp durante 24 horas, contadas a partir del primer mensaje enviado o recibido. Una vez transcurridas las 24 horas, se inicia una nueva conversación. Puedes realizar más de una conversación por día que se descontará del total de conversaciones.",
            ],
            currency: "$",
          },
          en: {
            name: "ADD-ON: PACK WHATSAPP100",
            value: "25",
            subtitle: "",
            periodicity: "one-time",
            items: [
              "100 WhatsApp conversations(*) per month / 3,500 messages.",
              "(*) WhatsApp Conversations: These are all messages with a unique WhatsApp number for 24 hours, counted from the first message sent or received. Once the 24 hours are up, a new conversation starts. You can have more than one conversation in a day, and it will be deducted from the total conversations.",
            ],
            currency: "USD",
          },
          cta: "buy",
        },
      ],
    };
  },
  created() {
    this.lang = this.$i18n?.locale ? this.$i18n?.locale : "en";
  },
  watch: {
    "$i18n.locale": function () {
      this.lang = this.$i18n?.locale ? this.$i18n?.locale : "en";
    },
  },
};
</script>
