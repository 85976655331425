<template>
  <nav
    id="navbarExample"
    class="navbar navbar-expand-lg fixed-top navbar-dark"
    aria-label="Main navigation"
  >
    <div class="container">
      <!-- Image Logo -->
      <router-link class="navbar-brand logo-image" @click="goToTop" to="/"
        ><img src="images/logo_blanco.png" alt="alternative"
      /></router-link>

      <!-- Text Logo - Use this if you don't have a graphic logo -->
      <!-- <a class="navbar-brand logo-text" href="index.html">Tivo</a> -->

      <button
        class="navbar-toggler p-0 border-0"
        type="button"
        id="navbarSideCollapse"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div
        class="navbar-collapse offcanvas-collapse"
        id="navbarsExampleDefault"
      >
        <ul class="navbar-nav ms-auto navbar-nav-scroll">
          <li class="nav-item">
            <a class="nav-link" href="/#header">{{ $t("home") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#features">{{ $t("features") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#details">{{ $t("benefits") }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/#pricing">{{ $t("pricing") }}</a>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="dropdown01"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              >{{ $t("more") }}</a
            >
            <ul class="dropdown-menu" aria-labelledby="dropdown01">
              <li>
                <a
                  class="dropdown-item"
                  href="https://admin.bookerix.com"
                  target="_blank"
                  >Log In</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item"
                  href="https://admin.bookerix.com/register"
                  target="_blank"
                  >{{ $t("signIn") }}</a
                >
              </li>
              <li><div class="dropdown-divider"></div></li>
              <li>
                <router-link class="dropdown-item" to="terms">{{
                  $t("terms")
                }}</router-link>
              </li>
              <li>
                <router-link class="dropdown-item mt-1" to="privacy">{{
                  $t("privacy")
                }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
        <span class="nav-item">
          <a
            class="btn-outline-sm"
            href="https://admin.bookerix.com"
            target="_blank"
            >{{ $t("access_panel") }}</a
          >
        </span>
        <ul class="navbar-nav navbar-nav-scroll">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="dropdown01"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-globe"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="dropdown01">
              <li>
                <span class="dropdown-item" href="#" @click="setLang('es')"
                  >Español</span
                >
              </li>
              <li><div class="dropdown-divider"></div></li>
              <li>
                <span class="dropdown-item" href="#" @click="setLang('en')"
                  >English</span
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- end of navbar-collapse -->
    </div>
    <!-- end of container -->
  </nav>
</template>

<script>
export default {
  created() {
    const lang = localStorage.getItem("lang");
    if (lang) this.$i18n.locale = lang;
    setTimeout(() => {
      const b = document.getElementById("bootstrap");
      if (b) return;
      const a = document.createElement("script");
      a.src = "js/bootstrap.min.js";
      a.id = "bootstrap";
      document.body.appendChild(a);
    }, 5000);
  },
  methods: {
    goToTop() {
      window.scrollTo(0, 0);
    },
    setLang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("lang", lang);
    },
  },
};
</script>
